import { api } from "../constants";

type OrderType = {
	id: string;
	email: string;
	cost: number;
	description: string;
	status: string;
	payData: string;
	countryCodeBE: string | null;
	addressFE: string | null;
	record: boolean;
	purchasedThroughPartner: string;
};

export type OrdersType = {
	orders: OrderType[];
	statusCounts: {
		draft: number;
		error: number;
		completed: number;
		cancelled: number;
	};
	totalTax: number;
	totalCost: number;
	netRevenue: number;
	totalPartnerDeductions: number;
};

async function getAllOrders(startData?: string, endData?: string) {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.url}/orders/get-all?startData=${startData}&endData=${endData}`, {
		method: "GET",
		headers: {
			Authorization: `Basic ${btoa(`${loginData}`)}`,
		},
	});
	const data = (await response.json()) as OrdersType;
	return data;
}

export { getAllOrders };
