import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@stitches/react";
import useStore from "../../store";

import Arrow from "../../components/icons/arrow";
import Book from "../../components/icons/book";
import { shortArticle } from "../../types/article";

type ArticleProps = {
	data: shortArticle;
};

const Article: FC<ArticleProps> = ({ data }) => {
	const { getArticleById } = useStore();
	const navigate = useNavigate();

	const buttonHandler = () => {
		getArticleById(data.id);
		navigate(`/articles/${data.id}`, { state: data.id });
	};

	return (
		<StyledArticle onClick={buttonHandler}>
			<ArticleContent>
				<Book />
				<h2 className="article__title">{data?.title}</h2>
			</ArticleContent>
			<Arrow />
		</StyledArticle>
	);
};

const StyledArticle = styled("article", {
	border: "1px solid #E0E1E2",
	cursor: "pointer",
	height: "56px",
	display: "flex",
	background: "#FBFBFB",
	alignItems: "center",
	borderRadius: "2px",
	marginBottom: "8px",
	justifyContent: "space-between",
	h2: {
		color: "#333333",
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "19px",
	},
	"&:last-child": {
		marginBottom: 0,
	},
});

const ArticleContent = styled("div", {
	display: "flex",
	padding: "8px",
	alignItems: "center",
});

export default Article;
