import { FC, useEffect, useState } from "react";

import { getAllUtmTags, UtmTagsType } from "../../api/utmTags";
import BigLoader from "../../components/common/BigLoader";
import InfoMessage from "../../components/InfoMessage";
import { infoMessageTypes } from "../../constants";
import { getCurrency } from "../../utils/getCurrency";

export const UtmTags: FC = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [utmTagsData, setutmTags] = useState<UtmTagsType | null>(null);

	useEffect(() => {
		const getUtmTags = async () => {
			try {
				const data = await getAllUtmTags();
				setutmTags(data);
			} catch (err) {
				if (err instanceof Error) {
					setError(err.message);
				}
			} finally {
				setLoading(false);
			}
		};

		getUtmTags();
	}, []);

	return (
		<main className="blogers">
			{loading ? (
				<BigLoader />
			) : error ? (
				<InfoMessage type={infoMessageTypes.error} message={error} />
			) : utmTagsData && utmTagsData.utmData.length ? (
				<>
					<div className="blogers__statistics">
						<p className="blogers__earned">
							Orders: <span className="blogers__earned--span">{utmTagsData.numberOfOrders}</span>
						</p>
						<div className="blogers__line" />
						<p className="blogers__earned">
							Revenue: <span className="blogers__earned--span">{getCurrency(utmTagsData.sumCost)}</span>
						</p>
					</div>
					<table className="bloger__table">
						<thead>
							<tr className="bloger__tr">
								<th className="bloger__th">Name</th>
								<th className="bloger__th">Orders</th>
								<th className="bloger__th">Revenue</th>
							</tr>
						</thead>
						<tbody className="bloger__tbody">
							{utmTagsData.utmData.map((item, index) => (
								<tr className="bloger__tr" key={index}>
									<td className="bloger__td">{item.utmContent}</td>
									<td className="bloger__td">{item.numberOfOrders}</td>
									<td className="bloger__td">{getCurrency(item.sumCost)}</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			) : (
				<p>There are no data</p>
			)}
		</main>
	);
};
