import { FC, ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { Content, ContentBig } from "./components/common/style";
import Header from "./components/Header";

type AppContentProps = {
	hideHeader?: boolean;
	children: ReactNode;
};

export const AppContent: FC<AppContentProps> = ({ hideHeader = false, children }) => {
	const location = useLocation();

	return (
		<>
			{location.pathname === "/statistics" ? (
				<ContentBig>
					{!hideHeader && <Header />}
					{children}
				</ContentBig>
			) : (
				<Content>
					{!hideHeader && <Header />}
					{children}
				</Content>
			)}
		</>
	);
};
