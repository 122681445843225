import { FC } from "react";
import { BrowserRouter } from "react-router-dom";

import { Routers } from "./components";
import { AuthProvider } from "./context/AuthContext";
import globalStyle from "./components/common/GlobalStyle";

const App: FC = () => {
	globalStyle();
	return (
		<BrowserRouter>
			<AuthProvider>
				<Routers />
			</AuthProvider>
		</BrowserRouter>
	);
};

export default App;
