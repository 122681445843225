import React, { ChangeEvent } from "react";

import { IPodcastLinks } from "../../types/article";
import { Fieldset, Input, Label } from "../common/style";

interface Props {
	podcastLinks: IPodcastLinks;
	setPodcastLinks: React.Dispatch<React.SetStateAction<IPodcastLinks>>;
}

const PodcastLinks: React.FC<Props> = ({ podcastLinks, setPodcastLinks }) => {
	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setPodcastLinks((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const names = {
		anchor: "Anchor",
		apple: "Apple podcasts",
		google: "Google podcasts",
		spotify: "Spotify",
		castbox: "Castbox",
		pocketcasts: "Pocket Casts",
		radiopublic: "RadioPublic",
	};

	return (
		<div>
			{Object.keys(podcastLinks).map((link) => (
				<Fieldset key={link} mb20>
					<Label htmlFor={link}>{names[link as keyof IPodcastLinks]}</Label>
					<Input
						id={link}
						type="text"
						name={link}
						value={podcastLinks[link as keyof IPodcastLinks]}
						onChange={handleInputChange}
					/>
				</Fieldset>
			))}
		</div>
	);
};

export default PodcastLinks;
