import SelectionUtils from "./Selection";

const addInlineStyles = () => {
	const styleId = "inline-tool-styling";
	if (!document.getElementById(styleId)) {
		const style = document.createElement("style");
		style.id = styleId;
		style.innerHTML = `
        .ce-inline-link-tool.ce-inline-tool-input--showed {
          padding: 0;
        }
        .ce-inline-tool-container {
          font-size: 12px;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
        .ce-inline-link-tool button {
          outline: none;
          padding: 5px 10px;
          display: block;
          box-sizing: border-box;
        }
        .ce-inline-link-tool {
          cursor: pointer;
          width: 24px;
          height: 34px;
          position: relative;
          margin-right: 2px;
          background: #fff;
          border: none;
        }
        .ce-inline-link-tool:hover {
          background-color: #eff2f5;
        }
        .ce-inline-link-tool-active {
          color: #388ae5;
        }
        .ce-inline-tool-input {
          padding: 0;
          width: 200px;
        }
        .ce-inline-editor-submit {
          padding: 5px 10px;
          border-radius: 3px;
          cursor: pointer;
          background: #fff;
          width: calc(100% - 12px);
          border: 1px solid rgba(201,201,204,.48);
          text-align: center;
          margin: auto;
          display: block;
        }
				.ce-inline-editor-submit:hover {
					background-color: #eff2f5;
				}
				.ce-inline-tool-article div {
					border: none;
					width: 100%;
					text-align: start;
					padding: 4px 10px;
					border-top: 1px solid rgba(201,201,204,.48);
					background: none;
					cursor: pointer;
				}
				.ce-inline-tool-article div:last-child {
					outline: none;
					border: 0;
					border-radius: 5px;
					font-size: 14px;
					padding: 7px 10px;
					margin-bottom: 6px;
					box-sizing: border-box;
					cursor: pointer;
				}
				.ce-inline-tool-article div:hover {
					background-color: rgba(232, 232, 235, 0.49);
				}
        .ce-inline-tool-input-url {
					width: calc(100% - 12px);
					margin: 6px;
					padding: 7px 10px;
					border-radius: 5px;
					border: solid 1px #E8E8EB;
					background-color: rgba(232, 232, 235, 0.49);
					outline: none;
					-webkit-appearance: none;
					box-sizing: border-box;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: -0.15px;
					font-family: inherit;
        }
        .ce-inline-tool-url-field {
        	border-top: 1px solid rgba(201,201,204,.48);
        }

        .ce-inline-tool-input:nth-child(1) {
          display: none;
        }
    `;
		document.head.appendChild(style);
	}
};

export default class LinkInlineTool implements EditorJS.InlineTool {
	public static isInline = true;

	public static title = "Link";

	static get sanitize(): EditorJS.SanitizerConfig {
		return {
			a: {
				href: true,
				rel: "nofollow",
				target: "_blank",
				title: true,
			},
		};
	}

	private readonly commandLink: string = "insertHTML";

	private readonly commandUnlink: string = "unlink";

	private CSS: {
		button: string;
		buttonActive: string;
		input: string;
		inputShowed: string;
	} | null = null;

	private nodes: any = {
		actionContainer: null,
		button: null,
		buttonSubmit: null,
		input: null,
		inputContainer: null,
		title: null,
		titleContainer: null,
		target: null,
		targetContainer: null,
	};

	private selection: SelectionUtils;

	private apiSelection: EditorJS.API["selection"];

	private inputOpened = false;

	private toolbar: any;

	articles;

	private inlineToolbar: any;

	private notifier: any;

	constructor({ api, config }: { api: EditorJS.API; config: any }) {
		this.articles = config.articles;
		this.toolbar = api.toolbar;
		this.inlineToolbar = api.inlineToolbar;
		this.notifier = api.notifier;
		this.apiSelection = api.selection;
		this.selection = new SelectionUtils();
		this.CSS = {
			button: "ce-inline-link-tool",
			buttonActive: "ce-inline-link-tool-active",
			input: "ce-inline-tool-input",
			inputShowed: "ce-inline-tool-input--showed",
		};

		addInlineStyles();
	}

	public surround(range: Range): void {
		if (range) {
			if (!this.inputOpened) {
				this.selection.setFakeBackground();
				this.selection.save();
			} else {
				this.selection.restore();
				this.selection.removeFakeBackground();
			}
			const parentAnchor = this.apiSelection.findParentTag("A");
			if (parentAnchor) {
				this.apiSelection.expandToTag(parentAnchor);
				this.unlink();
				this.closeActions();
				this.checkState();
				this.toolbar.close();
				return;
			}
		}
		this.toggleActions();
	}

	renderActions() {
		this.nodes.actionContainer = document.createElement("div");
		this.nodes.actionContainer.classList.add(this.CSS!.input);
		let containerName = "urlContainer";
		this.nodes[containerName] = document.createElement("div");
		this.nodes[containerName].classList.add("ce-inline-tool-container");
		const form = document.createElement("div");
		const button = document.createElement("div");
		// button.type = "button";
		button.classList.add("ce-inline-editor-submit");
		button.innerHTML = "set";
		const input = document.createElement("input");
		input.classList.add("ce-inline-tool-input-url");
		this.nodes.input = input;
		button.addEventListener("click", (e) => {
			e.preventDefault();
			this.dataEntered(input.value);
		});
		form.appendChild(input);
		form.appendChild(button);
		this.nodes[containerName].appendChild(form);
		this.nodes.actionContainer.appendChild(this.nodes[containerName]);
		if (Object.keys(this.articles).length !== 0) {
			const articlesContainer = document.createElement("div");
			articlesContainer.style.margin = "0 6px";
			Object.keys(this.articles).forEach((key) => {
				const article = this.articles[key];
				containerName = `${article.slug}Container`;
				this.nodes[containerName]! = document.createElement("div");
				this.nodes[containerName].classList.add("ce-inline-tool-article");
				const select = document.createElement("div");
				select.innerHTML = `<span>${article.title}</span>`;
				select.addEventListener("click", (e) => {
					e.preventDefault();
					this.dataEntered(`https://ptrk.fm/article/${article.slug}`);
				});
				this.nodes[containerName].appendChild(select);
				articlesContainer.appendChild(this.nodes[containerName]);
			});
			this.nodes.actionContainer.appendChild(articlesContainer);
		}
		return this.nodes.actionContainer;
	}

	private toggleActions(): void {
		if (!this.inputOpened) {
			this.openActions();
		} else {
			this.closeActions(false);
		}
	}

	checkState(): boolean {
		const anchorTag = this.apiSelection.findParentTag("A");

		if (anchorTag) {
			if (this.nodes) {
				this.nodes.button.classList.add(this.CSS!.buttonActive);
				this.openActions();

				const hrefAttr = anchorTag.getAttribute("href");
				this.nodes.input.value = hrefAttr || "";

				this.selection.save();
			}
		} else if (this.nodes) {
			this.nodes.button.classList.remove(this.CSS!.buttonActive);
		}

		return !!anchorTag;
	}

	render() {
		if (this.nodes) {
			this.nodes.button = document.createElement("button");
			this.nodes.button.type = "button";
			this.nodes.button.classList.add(this.CSS!.button);
			this.nodes.button.innerHTML =
				'<svg class="icon" width="12px" height="14px"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#link"></use></svg>';
		}

		return this.nodes!.button;
	}

	openActions() {
		if (this.nodes) {
			this.nodes.actionContainer.classList.add(this.CSS!.inputShowed);
		}
		this.inputOpened = true;
		this.nodes.input.focus();
	}

	closeActions(clearSavedSelection = true) {
		if (this.selection.isFakeBackgroundEnabled) {
			const currentSelection = new SelectionUtils();
			currentSelection.save();

			this.selection.restore();
			this.selection.removeFakeBackground();
			currentSelection.restore();
		}
		if (this.nodes) {
			this.nodes.actionContainer.classList.remove(this.CSS!.inputShowed);
		}
		if (clearSavedSelection) {
			this.selection.clearSaved();
		}
		this.inputOpened = false;
	}

	dataEntered(slug: string) {
		let value = slug;
		if (!value.trim()) {
			this.selection.restore();
			this.unlink();
			this.closeActions();
		}

		if (!this.validateURL(value)) {
			this.notifier.show({
				message: "Pasted link is not valid.",
				style: "error",
			});
			return;
		}

		value = this.prepareLink(value);

		this.selection.restore();
		this.selection.removeFakeBackground();
		this.insertLink(value);
		this.selection.collapseToEnd();
		this.inlineToolbar.close();
	}

	validateURL(str: string) {
		return !/\s/.test(str);
	}

	prepareLink(link: string) {
		let newLink = link;
		newLink = newLink.trim();
		newLink = this.addProtocol(newLink);
		return newLink;
	}

	addProtocol(link: string) {
		let newLink = link;

		if (/^(\w+):(\/\/)?/.test(newLink)) {
			return newLink;
		}
		const isInternal = /^\/[^/\s]/.test(newLink);
		const isAnchor = newLink.substring(0, 1) === "#";
		const isProtocolRelative = /^\/\/[^/\s]/.test(newLink);
		if (!isInternal && !isAnchor && !isProtocolRelative) {
			newLink = `http://${newLink}`;
		}
		return newLink;
	}

	insertLink(link: string) {
		const anchorTag = this.apiSelection.findParentTag("A");
		if (anchorTag) {
			this.apiSelection.expandToTag(anchorTag);
		}
		document.execCommand(this.commandLink, false, `<a href="${link}">${SelectionUtils.text}</a>`);
	}

	unlink() {
		document.execCommand(this.commandUnlink);
	}
}
