import { api } from "../constants";

type UtmTagType = {
	sumCost: number;
	utmContent: string;
	numberOfOrders: number;
};

export type UtmTagsType = {
	sumCost: number;
	utmData: UtmTagType[];
	numberOfOrders: number;
};

async function getAllUtmTags() {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.url}/utm-tracking/statistic`, {
		method: "GET",
		headers: {
			Authorization: `Basic ${btoa(`${loginData}`)}`,
		},
	});
	const data = (await response.json()) as UtmTagsType;
	return data;
}

export { getAllUtmTags };
