import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";

import { getAllShortLinks, getAllTags, ShortLinksType, Tags } from "../../api/shortLink";
import BigLoader from "../../components/common/BigLoader";
import InfoMessage from "../../components/InfoMessage";
import { api, infoMessageTypes } from "../../constants";
import { CopyButton } from "./components/CopyButton";

export const ShortLinks: FC = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [tags, setTags] = useState<Tags | null>(null);
	const [shortLinks, setShortLinks] = useState<ShortLinksType | null>(null);

	const [route, setRoute] = useState("");
	const [tag, setTag] = useState("");
	const [redirect, setRedirect] = useState("");
	const [formLoading, setFormLoading] = useState(false);
	const [formError, setFormError] = useState<string | null>(null);

	const handelRouteInput = (event: ChangeEvent<HTMLInputElement>) => {
		setFormError(null);

		setRoute(event.target.value);
	};

	const handelTagInput = (event: ChangeEvent<HTMLInputElement>) => {
		setFormError(null);

		setTag(event.target.value);
	};

	const handelRedirectInput = (event: ChangeEvent<HTMLInputElement>) => {
		setFormError(null);

		setRedirect(event.target.value);
	};

	const handleFormSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (route && tag && redirect) {
			try {
				setFormError(null);
				setFormLoading(true);

				const response = await fetch(`${api.slUrl}/add`, {
					method: "POST",
					body: JSON.stringify({ route, trackingTag: tag, redirectUrl: redirect }),
					headers: {
						"Content-Type": "application/json",
					},
				});

				if (response.ok) {
					setShortLinks((prev) =>
						prev
							? [
									{
										route,
										redirectUrl: redirect,
										trackingTag: tag,
										createdAt: "",
									},
									...prev,
								]
							: [
									{
										route,
										redirectUrl: redirect,
										trackingTag: tag,
										createdAt: "",
									},
								],
					);

					setTags((prev) => (prev ? [tag, ...prev] : [tag]));

					setRoute("");
					setTag("");
					setRedirect("");
				}
			} catch (err) {
				if (err instanceof Error) {
					setFormError(err.message);
				}
			} finally {
				setFormLoading(false);
			}
		}
	};

	useEffect(() => {
		const getShortLinks = async () => {
			try {
				const dataShortLinks = await getAllShortLinks();
				const dataTags = await getAllTags();

				setShortLinks(dataShortLinks);
				setTags(dataTags);
			} catch (err) {
				if (err instanceof Error) {
					setError(err.message);
				}
			} finally {
				setLoading(false);
			}
		};

		getShortLinks();
	}, []);

	return (
		<main className="shortLink">
			<form className="shortLink__form" onSubmit={handleFormSubmit}>
				<fieldset className="shortLink__fieldset">
					<input
						className="shortLink__input"
						value={route}
						type="text"
						name="route"
						id="route"
						placeholder="Route"
						required
						onChange={handelRouteInput}
					/>
					<input
						list="tags"
						className="shortLink__input"
						value={tag}
						type="text"
						name="tag"
						id="tag"
						placeholder="Tracking Tag"
						required
						onChange={handelTagInput}
					/>
					{tags && tags.length ? (
						<datalist id="tags">
							{tags.map((item, index) => (
								<option value={item} key={index} />
							))}
						</datalist>
					) : null}
				</fieldset>
				<input
					className="shortLink__input"
					value={redirect}
					type="text"
					name="redirect"
					id="redirect"
					placeholder="Redirect Url"
					required
					onChange={handelRedirectInput}
				/>
				<button className="shortLink__btn" type="submit">
					{formLoading ? <BigLoader /> : "Create"}
				</button>
			</form>
			{formError && <p>{formError}</p>}
			{loading ? (
				<BigLoader />
			) : error ? (
				<InfoMessage type={infoMessageTypes.error} message={error} />
			) : shortLinks && shortLinks.length ? (
				<table className="shortLink__table">
					<tbody className="shortLink__tbody">
						{shortLinks.map((item, index) => (
							<tr className="shortLink__tr" key={index}>
								<td className="shortLink__td" title={item.route}>
									<CopyButton route={item.route} />
								</td>
								<td className="shortLink__td" title={item.redirectUrl}>
									<a href={item.redirectUrl} target="_blank" rel="noopener noreferrer">
										{item.redirectUrl}
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<p>There are no short links</p>
			)}
		</main>
	);
};
