import { createContext, FC, useCallback, useEffect, useMemo, useState } from "react";

import { AppContent } from "../AppContent";

import BigLoader from "../components/common/BigLoader";
import { Container } from "../components/common/style";

import { api } from "../constants";

export const AuthContext = createContext({
	isLoggedIn: false,
	loading: false,
	handleLoggedIn: (_value: boolean) => {},
});

export const AuthProvider: FC = ({ children }) => {
	const [loading, setLoading] = useState(true);
	const [isLoggedIn, setLoggedIn] = useState(false);

	const loginData = localStorage.getItem("login");

	const handleLoggedIn = useCallback((value: boolean) => {
		setLoggedIn(value);
	}, []);

	useEffect(() => {
		if (loginData) {
			const handleFetch = async () => {
				const response = await fetch(`${api.url}/admin-login`, {
					method: "POST",
					headers: {
						Authorization: `Basic ${btoa(`${loginData}`)}`,
					},
				});

				if (!response.ok) {
					setLoggedIn(false);
					setLoading(false);
				}

				const data = await response.json();

				if (data.message === "ok") {
					setLoggedIn(true);
					setLoading(false);
				}
			};

			try {
				handleFetch();
			} catch (error) {
				setLoggedIn(false);
				setLoading(false);
			}
		} else {
			setLoggedIn(false);
			setLoading(false);
		}
	}, [loginData]);

	const contextValue = useMemo(
		() => ({
			isLoggedIn,
			loading,
			handleLoggedIn,
		}),
		[isLoggedIn, loading, handleLoggedIn],
	);

	return (
		<AuthContext.Provider value={contextValue}>
			{loading ? (
				<Container>
					<AppContent>
						<BigLoader />
					</AppContent>
				</Container>
			) : (
				children
			)}
		</AuthContext.Provider>
	);
};
