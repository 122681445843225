import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import Image from "@editorjs/image";
import Marker from "@editorjs/marker";
import NestedList from "@editorjs/nested-list";
import Quote from "@editorjs/quote";
import SimpleImage from "@editorjs/simple-image";
import { normalizedArticles } from "../../types/article";

import { api } from "../../constants/index";
import CustomLinkTool from "./LinkTool";

export const createTools = (articles: normalizedArticles) => ({
	embed: Embed,
	marker2: {
		class: CustomLinkTool,
		config: {
			articles,
		},
	},
	image: {
		class: Image,
		config: {
			uploader: {
				async uploadByFile(file: File) {
					const res = await fetch(`${api.url}/img`, {
						method: "POST",
						headers: {
							"Content-Type": file.type,
							Authorization: `Basic ${btoa(`${localStorage.getItem("login")}`)}`,
						},
						body: file,
					});
					const { data } = await res.json();
					return {
						success: 1,
						file: {
							url: `${api.url}/img/${data.id}`,
						},
					};
				},
			},
		},
	},
	header: {
		class: Header,
		config: {
			placeholder: "Enter a header",
			levels: [1, 2, 3],
			defaultLevel: 1,
		},
	},
	simpleImage: SimpleImage,
	marker: Marker,
	quote: {
		class: Quote,
		inlineToolbar: true,
		shortcut: "CMD+SHIFT+O",
		config: {
			quotePlaceholder: "Enter a quote",
			captionPlaceholder: "Quote's author",
		},
	},
	list: {
		class: NestedList,
		inlineToolbar: true,
	},
});
