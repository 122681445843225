import { FC, useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";

import { StatisticType } from "../../../api/statistics";
import { getDate } from "../../../utils/getDate";

type ChartProps = {
	data: StatisticType;
};

export const Chart: FC<ChartProps> = ({ data }) => {
	const isSomeDataMoreThen10 = useMemo(
		() =>
			data.redirectsDataPerDay.some(
				(redirectsData) => redirectsData.redirects.reduce((a, b) => a + b, 0) > 10,
			),
		[data],
	);

	const isSomeDataMoreThen1000 = useMemo(
		() =>
			data.redirectsDataPerDay.some(
				(redirectsData) => redirectsData.redirects.reduce((a, b) => a + b, 0) > 1000,
			),
		[data],
	);

	const gridWidth = isSomeDataMoreThen1000 ? "95%" : isSomeDataMoreThen10 ? "97%" : "97%";

	const [removeAll, setRemoveAll] = useState(false);
	const [selectedRoutes, setSelectedRoutes] = useState<{ name: string; isSelected: boolean }[]>();

	const xAxisData = useMemo(
		() =>
			data.redirectsDataPerDay.length
				? data.redirectsDataPerDay.map((item) => getDate(item.date))
				: [getDate(new Date().toDateString())],
		[data],
	);

	const seriesData = useMemo(
		() =>
			selectedRoutes
				? selectedRoutes.map((route, index) => ({
						name: route.name,
						type: "bar",
						stack: "total",
						data: data.redirectsDataPerDay.length
							? data.redirectsDataPerDay.map((item) => {
									if (route.isSelected) {
										return item.redirects[index];
									}

									return 0;
								})
							: [0],
					}))
				: [],
		[selectedRoutes, data],
	);

	const options = useMemo(
		() => ({
			grid: {
				height: "90%",
				width: gridWidth,
				top: 10,
				right: 0,
			},
			xAxis: {
				type: "category",
				interval: 0,
				data: xAxisData,
				axisLabel: {
					type: "value",
					fontWeight: 500,
					color: "#747474",
				},
				axisTick: {
					show: false,
				},
				axisLine: {
					show: false,
				},
				splitLine: {
					show: false,
				},
				z: 10,
			},
			yAxis: {
				type: "value",
				minInterval: 1,
			},
			series: seriesData,
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: "shadow",
				},
				formatter: (params: any) => {
					const total = params.reduce((acc: number, item: any) => acc + item.data, 0);

					let rez = `<p>${params[0].axisValue}</p>`;
					rez += `<p class="statistics__total statistics__row">Total: <span class="statistics__row--span">${total}</span></p>`;

					params.forEach((item: any) => {
						const param = `<p class="statistics__row"><span>${item.marker} ${item.seriesName}:</span> <span class="statistics__row--span">${item.data}</span></p>`;
						rez += param;
					});

					return rez;
				},
			},
		}),
		[data, seriesData],
	);

	const handleRouteToggle = (param: string) => {
		setSelectedRoutes((prevParams) => {
			const currentRoute = prevParams?.find((item) => item.name === param);

			if (currentRoute && currentRoute.isSelected) {
				return prevParams?.map((item) => {
					if (item.name === currentRoute.name) {
						return { name: item.name, isSelected: false };
					}

					return item;
				});
			}

			return prevParams?.map((item) => {
				if (item.name === currentRoute?.name) {
					return { name: item.name, isSelected: true };
				}

				return item;
			});
		});
	};

	const handleButton = () => {
		if (removeAll) {
			setSelectedRoutes((prev) => {
				if (prev) return prev.map((item) => ({ name: item.name, isSelected: true }));

				return prev;
			});
			setRemoveAll(false);
		} else {
			setSelectedRoutes((prev) => {
				if (prev) return prev.map((item) => ({ name: item.name, isSelected: false }));

				return prev;
			});
			setRemoveAll(true);
		}
	};

	useEffect(() => {
		const routes = data.routes.map((route) => ({
			name: route,
			isSelected: true,
		}));

		setSelectedRoutes(routes);
	}, [data]);

	return (
		<>
			<h2 className="statistics__title">{data.tag}</h2>
			<div className="statistics__wrapper">
				<ReactECharts option={options} className="statistics__chart" />
				<div className="statistics__data">
					<p className="statistics__subtitle">
						Total redirects: <span className="statistics__subtitle--span">{data.countRedirects}</span>
					</p>
					<p className="statistics__subtitle">
						Redirects for 30 days:{" "}
						<span className="statistics__subtitle--span">{data.countRedirectsByPeriod}</span>
					</p>
					<hr className="statistics__hr" />
					<div className="statistics__list">
						<p className="statistics__subtitle">Select Routes</p>
						{selectedRoutes?.map((route, index) => (
							<label className="statistics__label" htmlFor={route.name} key={index}>
								<input
									type="checkbox"
									name={route.name}
									id={route.name}
									checked={route.isSelected}
									onChange={() => handleRouteToggle(route.name)}
								/>
								{route.name}
							</label>
						))}
					</div>
					<button className="statistics__btn" type="button" onClick={handleButton}>
						{removeAll ? "Select All" : "Remove All"}
					</button>
				</div>
			</div>
		</>
	);
};
