import { api } from "../constants";

export type GroupTherapyType = {
	date: string;
	name: string;
	emails: string[];
	count: number;
}[];

async function getGroupTherapy() {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.url}/group-therapy/get-for-admin`, {
		method: "GET",
		headers: {
			Authorization: `Basic ${btoa(`${loginData}`)}`,
		},
	});
	const data = (await response.json()) as GroupTherapyType;
	return data;
}

async function sendConferenceLink(body: {
	linkToConference: string;
	meetingId: string;
	passcode: string;
	emails: string[];
	date: string;
}) {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.url}/group-therapy/send-conference-link`, {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			"Content-Type": "application/json",
			Authorization: `Basic ${btoa(`${loginData}`)}`,
		},
	});
	const data = (await response.json()) as string;
	return data;
}

export { getGroupTherapy, sendConferenceLink };
