import { FC, useEffect } from "react";
import useStore from "../../store";

import { InfoMessage } from "../../components";
import BigLoader from "../../components/common/BigLoader";
import { infoMessageTypes } from "../../constants";
import ArticlesList from "./ArticlesList";

const Articles: FC = () => {
	const { normalizedArticlesIds, normalizedArticles, error, loading, getArticles } = useStore();
	useEffect(() => {
		if (Object.keys(normalizedArticles).length === 0) {
			getArticles();
		}
	}, []);
	return (
		<main>
			<div>
				{
					// eslint-disable-next-line no-nested-ternary
					loading ? (
						<BigLoader />
					) : error ? (
						<InfoMessage type={infoMessageTypes.error} message={error} />
					) : (
						<ArticlesList articlesIds={normalizedArticlesIds} articles={normalizedArticles} />
					)
				}
			</div>
		</main>
	);
};

export default Articles;
