import React, { FC } from "react";
import { useLocation } from "react-router-dom";

import { normalizedArticles, normalizedArticlesIds } from "../../types/article";
import Article from "./Article";

type ArticlesListProps = {
	articlesIds: normalizedArticlesIds;
	articles: normalizedArticles;
};

const ArticlesList: FC<ArticlesListProps> = ({ articlesIds, articles }) => {
	const location = useLocation();

	const [currentPage] = React.useState<number>(parseInt(location.search?.split("=")[1], 10) || 1);

	const articlesPerPage = articlesIds.length;
	const lastArticleIndex = currentPage * articlesPerPage;
	const firstArticleIndex = lastArticleIndex - articlesPerPage;
	const currentArticlesIds = articlesIds.slice(firstArticleIndex, lastArticleIndex);

	return currentArticlesIds.length ? (
		<div>
			{currentArticlesIds.map((id) => (
				<Article key={id} data={articles[id]} />
			))}
		</div>
	) : (
		<p>Empty</p>
	);
};

export default ArticlesList;
