import { globalCss } from "@stitches/react";

const globalStyles = globalCss({
	"*": {
		margin: 0,
		padding: 0,
		boxSizing: "border-box",
	},
	body: {
		margin: 0,
		fontFamily: '"Source Sans Pro", sans-serif',
		fontWeight: 400,
		fontSize: 14,
		fontStyle: "normal",
		backgroundColor: "#f8f8f8",
	},
	html: {
		scrollBehavior: "smooth",
		padding: 0,
		margin: 0,
	},
	h1: {
		fontSize: 30,
	},
	h2: {
		fontSize: 22,
	},
	p: {
		fontSize: 14,
		fontWeight: 400,
		margin: 0,
	},
});

export default globalStyles;
