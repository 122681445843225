export default class SelectionUtils {
  public instance: Selection | null = null;

  public selection: Selection | null = null;

  public savedSelectionRange: Range | null = null;

  public isFakeBackgroundEnabled = false;

  private readonly commandRemoveFormat: string = 'removeFormat';

  static get range(): Range | null {
    const selection = window.getSelection();

    return selection && selection.rangeCount ? selection.getRangeAt(0) : null;
  }

  static get text(): string {
    return window.getSelection ? window.getSelection()!.toString() : '';
  }

  public static get(): Selection | null {
    return window.getSelection();
  }

  public removeFakeBackground() {
    if (!this.isFakeBackgroundEnabled) {
      return;
    }

    this.isFakeBackgroundEnabled = false;
    document.execCommand(this.commandRemoveFormat);
  }

  public setFakeBackground() {
    this.isFakeBackgroundEnabled = true;
  }

  public save(): void {
    this.savedSelectionRange = SelectionUtils.range;
  }

  public restore(): void {
    if (!this.savedSelectionRange) {
      return;
    }

    const sel = window.getSelection();
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(this.savedSelectionRange);
    }
  }

  public clearSaved(): void {
    this.savedSelectionRange = null;
  }

  public collapseToEnd(): void {
    const sel = window.getSelection();
    const range = document.createRange();
    if (sel && sel.focusNode) {
      range.selectNodeContents(sel.focusNode);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }
}
