/* eslint-disable no-nested-ternary */
import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../../store";

import { InfoMessage } from "../../components";
import BigLoader from "../../components/common/BigLoader";
import { BackButton, Button, SmallContainer } from "../../components/common/style";
import { infoMessageTypes } from "../../constants";
import EditEditorForm from "./EditEditorForm";

const EditArticle: FC = () => {
	const location = useLocation();

	const navigate = useNavigate();
	const { getArticleById, article, loading, error, updateNormalizedArticles, setError } = useStore();
	const goBackButtonHandler = () => {
		if (article?.hidden) {
			navigate("/archived");
		} else {
			navigate("/");
		}
	};

	useEffect(() => {
		if (!article && !loading) {
			if (location.pathname.includes("articles")) {
				const pathnameArray = location.pathname.split("/");
				const idFromUrl = pathnameArray[pathnameArray.length - 1];
				if (idFromUrl) {
					getArticleById(idFromUrl);
				}
			} else {
				goBackButtonHandler();
			}
		}
	}, []);
	useEffect(() => {
		if (error) {
			setError(null);
			goBackButtonHandler();
		}
	}, [error]);

	return (
		<>
			<BackButton>
				<Button css={{ background: "#fff" }} onClick={goBackButtonHandler}>
					Back
				</Button>
			</BackButton>
			<main className="edit">
				<SmallContainer>
					{loading ? (
						<div>
							<BigLoader />
						</div>
					) : error ? (
						<InfoMessage type={infoMessageTypes.error} message={error} />
					) : !article ? null : (
						<>{article && <EditEditorForm article={article} updateArticle={updateNormalizedArticles} />}</>
					)}
				</SmallContainer>
			</main>
		</>
	);
};

export default EditArticle;
