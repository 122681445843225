import { api } from "../constants";

type ShortLinkType = {
	route: string;
	redirectUrl: string;
	trackingTag: string;
	createdAt: string;
};

export type ShortLinksType = ShortLinkType[];

async function getAllShortLinks() {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.slUrl}/get-all`, {
		method: "GET",
		headers: {
			Authorization: `Basic ${btoa(`${loginData}`)}`,
		},
	});
	const data = (await response.json()) as ShortLinksType;
	return data;
}

export type Tags = string[];

async function getAllTags() {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.slUrl}/tags`, {
		method: "GET",
		headers: {
			Authorization: `Basic ${btoa(`${loginData}`)}`,
		},
	});
	const data = (await response.json()) as Tags;
	return data;
}

export { getAllShortLinks, getAllTags };
