import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";

import { getAllOrders, OrdersType } from "../../api/orders";
import BigLoader from "../../components/common/BigLoader";
import InfoMessage from "../../components/InfoMessage";
import { infoMessageTypes } from "../../constants";
import { getCurrency } from "../../utils/getCurrency";

const getDate = (value: string) => {
	const date = new Date(value);
	const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
	const month = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
	const year = date.getFullYear();

	return `${day}-${month}-${year}`;
};

export const Orders: FC = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [ordersData, setOrders] = useState<OrdersType | null>(null);
	const [status, setStatus] = useState("all");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;
		setStatus(value);
	};

	const handleStartDate = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setStartDate(value);
	};

	const handleEndDate = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setEndDate(value);
	};

	const filteredOrdersData = useMemo(() => {
		if (ordersData && ordersData.orders.length) {
			return ordersData.orders.filter((order) => (status !== "all" ? order.status === status : true));
		}

		return [];
	}, [ordersData, status]);

	useEffect(() => {
		const getOrders = async () => {
			try {
				const data = await getAllOrders(startDate, endDate);
				setOrders(data);
			} catch (err) {
				if (err instanceof Error) {
					setError(err.message);
				}
			} finally {
				setLoading(false);
			}
		};

		getOrders();
	}, [startDate, endDate]);

	return (
		<main className="orders">
			{loading ? (
				<BigLoader />
			) : error ? (
				<InfoMessage type={infoMessageTypes.error} message={error} />
			) : ordersData && ordersData.orders ? (
				<>
					{ordersData.orders.length ? (
						<div className="orders__statistics">
							<div className="orders__wrapper">
								<p className="orders__status orders__status--completed">
									Completed: <span className="orders__status--span">{ordersData.statusCounts.completed}</span>
								</p>
								<p className="orders__status orders__status--draft">
									Draft: <span className="orders__status--span">{ordersData.statusCounts.draft}</span>
								</p>
								<p className="orders__status orders__status--cancelled">
									Cancelled: <span className="orders__status--span">{ordersData.statusCounts.cancelled}</span>
								</p>
								<p className="orders__status orders__status--error">
									Error: <span className="orders__status--span">{ordersData.statusCounts.error}</span>
								</p>
							</div>
							<div className="orders__wrapper">
								<p className="orders__earned">
									Revenue: <span className="orders__earned--span">{getCurrency(ordersData.totalCost)}</span>
								</p>
								<div className="orders__line" />
								<p className="orders__earned">
									Profit: <span className="orders__earned--span">{getCurrency(ordersData.netRevenue)}</span>
								</p>
								<div className="orders__line" />
								<p className="orders__earned">
									Tax: <span className="orders__earned--span">{getCurrency(ordersData.totalTax)}</span>
								</p>
								<div className="orders__line" />
								<p className="orders__earned">
									Partners:{" "}
									<span className="orders__earned--span">
										{getCurrency(ordersData.totalPartnerDeductions)}
									</span>
								</p>
							</div>
						</div>
					) : null}
					<div className="orders__filters">
						<label htmlFor="status" className="order__label">
							Status
							<select id="status" onChange={handleSelect} className="order__select">
								<option value="all">All</option>
								<option value="completed">Completed</option>
								<option value="cancelled">Cancelled</option>
								<option value="draft">Draft</option>
								<option value="error">Error</option>
							</select>
						</label>
						<label htmlFor="startDate" className="order__label">
							Start date
							<input
								className="input"
								type="date"
								name="startDate"
								id="startDate"
								value={startDate}
								onChange={handleStartDate}
							/>
						</label>
						<label htmlFor="endDate" className="order__label">
							End date
							<input
								className="input"
								type="date"
								name="endDate"
								id="endDate"
								value={endDate}
								onChange={handleEndDate}
							/>
						</label>
					</div>
					{ordersData.orders.length ? (
						<table className="order__table">
							<thead>
								<tr className="order__tr">
									<th className="order__th"> </th>
									<th className="order__th">Product</th>
									<th className="order__th">Price</th>
									<th className="order__th">Partner</th>
									<th className="order__th">Email</th>
									<th className="order__th">Country</th>
									<th className="order__th" style={{ width: "85px" }}>
										Date
									</th>
								</tr>
							</thead>
							<tbody className="order__tbody">
								{filteredOrdersData.map((item) => (
									<tr className="order__tr" key={item.id}>
										<td className="order__td">
											<div className={`order__status order__status--${item.status}`} />
										</td>
										<td className="order__td">
											<p>
												{item.record ? "Запись | " : ""}
												{item.description === "Самооценка, Травмы, Отношения, Карьера, Воркбук «Про Башку»" ||
												item.description === "Самооценка, Травмы, Отношения, Карьера, Воркбук «Про башку»"
													? "Пак семинаров + воркбук"
													: item.description === "Самооценка, Травмы, Отношения, Карьера" ||
														  item.description === "Травмы, Отношения, Карьера, Самооценка | запись семинара"
														? "Пак семинаров"
														: item.description}
											</p>
										</td>
										<td className="order__td">
											<p>{getCurrency(item.cost)}</p>
										</td>
										<td className="order__td">
											<p>{item.purchasedThroughPartner.split("-")[0]}</p>
										</td>
										<td className="order__td">
											<p>{item.email}</p>
										</td>
										<td className="order__td" title={`${item.countryCodeBE || ""} - ${item.addressFE || ""}`}>
											<p>{item.addressFE?.split(",")[0] || ""}</p>
										</td>
										<td className="order__td">
											<p>{getDate(item.payData)}</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<p>There are no orders</p>
					)}
				</>
			) : (
				<p>There are no orders</p>
			)}
		</main>
	);
};
