import { api } from "../constants";

async function getAllArticles() {
	const response = await fetch(`${api.url}/articles?sortByDate=descending&cacheDisabled=1`, {
		method: "GET",
	});
	const { data } = await response.json();
	return data;
}

async function getArticle(id: string) {
	const response = await fetch(`${api.url}/articles/${id}`, {
		method: "GET",
	});
	const { data } = await response.json();
	return data;
}

async function getAllArchivedArticles() {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.url}/articles/archived?sortByDate=descending&cacheDisabled=1`, {
		method: "GET",
		headers: {
			Authorization: `Basic ${btoa(`${loginData}`)}`,
		},
	});
	const { data } = await response.json();
	return data;
}

export { getAllArticles, getArticle, getAllArchivedArticles };
