import { FC, useRef } from "react";

type CopyButtonProps = {
	route: string;
};

export const CopyButton: FC<CopyButtonProps> = ({ route }) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	const copyToClipBoard = async (url: string) => {
		if (buttonRef.current) {
			if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
				await navigator.clipboard.writeText(url);
				buttonRef.current.dataset.title = "Link copied";
			} else {
				buttonRef.current.dataset.title = "Clipboard does not work";
			}
			buttonRef.current.classList.add("shortLink__copy--active");

			setTimeout(() => {
				buttonRef.current?.classList.remove("shortLink__copy--active");
			}, 800);
		}
	};

	const shareHandler = async () => {
		const shareData = {
			title: "Short link",
			url: `${process.env.REACT_APP_SL_URL}/${route}`,
		};

		if (navigator.canShare) {
			if (navigator.canShare(shareData)) {
				try {
					await navigator.share(shareData);
				} catch (error) {
					console.log(error);
				}
			} else {
				await copyToClipBoard(shareData.url);
			}
		} else {
			await copyToClipBoard(shareData.url);
		}
	};

	return (
		<button type="button" className="shortLink__copy" ref={buttonRef} onClick={shareHandler}>
			{route}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="12"
				height="12"
				viewBox="0 0 24 24"
				fill="none"
				stroke="#000000"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				aria-hidden="true"
			>
				<rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
				<path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
			</svg>
		</button>
	);
};
