import React from "react";
import { styled } from "@stitches/react";

interface Props {
	isActive: boolean;
	setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const Toggle: React.FC<Props> = ({ isActive, setIsActive }) => (
	<Label>
		<input checked={isActive} onChange={() => setIsActive((prev) => !prev)} type="checkbox" />
		<i />
	</Label>
);

const Label = styled("div", {
	display: "inline-block",
	cursor: "pointer",
	"& input": {
		display: "none",
		"&:checked + i": {
			backgroundColor: "#5bda4e",
		},
		"&:checked + i::before": {
			transform: "translate3d(18px, 2px, 0) scale3d(0, 0, 0)",
		},
		"&:checked + i::after": {
			transform: "translate3d(18px, 2px, 0)",
		},
	},
	"& i": {
		position: "relative",
		display: "inline-block",
		width: "32px",
		height: "16px",
		backgroundColor: "#e6e6e6",
		borderRadius: "23px",
		verticalAlign: "text-bottom",
		transition: "all 0.3s",

		"&:before": {
			content: "",
			position: "absolute",
			left: -1,
			top: -1,
			width: "30px",
			height: "14px",
			backgroundColor: "#fff",
			borderRadius: "11px",
			transform: "translate3d(2px, 2px, 0) scale3d(1, 1, 1)",
			transition: "all 0.3",
		},
		"&:after": {
			content: "",
			position: "absolute",
			left: -1,
			top: -1,
			width: "14px",
			height: "14px",
			backgroundColor: "#fff",
			borderRadius: "11px",
			boxShadow: "0 1px 2px rgba(0, 0, 0, 0.24)",
			transform: "translate3d(2px, 2px, 0)",
			transition: "all 0.2s ease-in-out",
		},
	},
});

export default Toggle;
