import { FC, useEffect, useState } from "react";

import { getStatistics, StatisticsType } from "../../api/statistics";
import BigLoader from "../../components/common/BigLoader";
import InfoMessage from "../../components/InfoMessage";
import { infoMessageTypes } from "../../constants";
import { Chart } from "./components/Chart";

export const Statistics: FC = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	const [statistics, setStatistics] = useState<StatisticsType | null>(null);

	useEffect(() => {
		const getStatisticsData = async () => {
			try {
				const data = await getStatistics();
				setStatistics(data);
			} catch (err) {
				if (err instanceof Error) {
					setError(err.message);
				}
			} finally {
				setLoading(false);
			}
		};

		getStatisticsData();
	}, []);

	return (
		<main className="statistics">
			{loading ? (
				<BigLoader />
			) : error ? (
				<InfoMessage type={infoMessageTypes.error} message={error} />
			) : statistics && statistics.length ? (
				<>
					{statistics.map((item, index) => (
						<Chart key={index} data={item} />
					))}
				</>
			) : (
				<p>There are no statistics data</p>
			)}
		</main>
	);
};
