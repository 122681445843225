import { api } from "../constants";

export type StatisticType = {
	tag: string;
	countRedirects: number;
	countRedirectsByPeriod: number;
	routes: string[];
	redirectsDataPerDay: {
		date: string;
		total: number;
		redirects: number[];
	}[];
};

export type StatisticsType = StatisticType[];

async function getStatistics() {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.slUrl}/redirect-statistic`, {
		method: "GET",
		headers: {
			Authorization: `Basic ${btoa(`${loginData}`)}`,
		},
	});
	const data = (await response.json()) as StatisticsType;
	return data;
}

export { getStatistics };
