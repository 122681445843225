import { api } from "../constants";

type BlogerType = {
	id: string;
	imageLink: string;
	password: string;
	referralCode: string;
	name: string;
	referralClicksCount: number;
	referralsCount: number;
	referralsSum: number;
};

export type BlogersType = {
	partnersData: BlogerType[];
	totalVisits: number;
	totalPurchases: number;
	totalRevenue: number;
};

async function getAllBlogers() {
	const loginData = localStorage.getItem("login");

	const response = await fetch(`${api.url}/influencer/admin-statistic`, {
		method: "GET",
		headers: {
			Authorization: `Basic ${btoa(`${loginData}`)}`,
		},
	});
	const data = (await response.json()) as BlogersType;
	return data;
}

export { getAllBlogers };
